<template>
  <div>
    <v-data-table
      dense
      :headers="headers"
      :items="attendanceData"
      :items-per-page="5"
      sort-by="date"
      :sort-desc="sortDesc"
      :search="search"
      class="elevation-1"
      @click:row="handleRowClick"
    >
      <template v-slot:[`item.workTime`]="{ item }">
        <!-- <v-btn color="primary" dark plain class="text-h6">123小时</v-btn>
        <v-chip :color="item.workTime > 8 * 3600 * 1000 ? 'green' : 'red'" dark>
          {{ showFormattedWorkTime(item.workTime) }}
        </v-chip> -->

        <v-toolbar flat>
          <v-toolbar-title>{{
            showFormattedWorkTime(item.workTime)
          }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-chip
            :color="item.workTime > 8 * 3600 * 1000 ? 'green' : 'red'"
            dark
          >
            {{ showFormattedWorkTime(item.workTime - 8 * 3600 * 1000) }}
          </v-chip>
        </v-toolbar>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="text-subtitle-1"
            >{{ getWorkDays() }}天</v-toolbar-title
          >
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-toolbar-title class="text-subtitle-1">{{
            showFormattedWorkTime(getWorkTime())
          }}</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <!-- <v-toolbar-title class="text-subtitle-1">{{getExtraWorkHours()}}</v-toolbar-title> -->

          <v-chip :color="getExtraWorkTime() > 0 ? 'green' : 'red'" dark>
            {{ showFormattedWorkTime(getExtraWorkTime()) }}
          </v-chip>

          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            outlined
            class="mb-2"
            @click="dialog = !dialog"
          >
            添加工时
          </v-btn>
        </v-toolbar>
        <!-- <v-text-field
          v-model="search"
          prepend-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          type="month"
        ></v-text-field> -->

        <v-row>
          <v-col cols="7">
            <v-text-field
              v-model="search"
              prepend-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              type="month"
            ></v-text-field>
          </v-col>
          <!-- <v-col cols="2">
            <v-btn plain color="primary" dark> 导入 </v-btn>
          </v-col>
                   <v-col cols="2">
            <v-btn plain color="primary" dark> 导出 </v-btn>
          </v-col> -->
        </v-row>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="editedItem.date"
                  label="日期"
                  type="date"
                  :readonly="editedIndex > -1"
                  :rules="[() => !!editedItem.date || '日期必填']"
                  :error-messages="errorMessage"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="editedItem.start"
                  type="time"
                  label="上班时间"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="editedItem.end"
                  type="time"
                  label="下班时间"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            v-show="showDeleteBtn"
            @click="deleteItem"
          >
            删除
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save"
            :disabled="errorMessage.length > 0"
          >
            保存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getAttendanceData, setAttendanceData } from "../data";
import { calcWorkTime, calcWorkTimeWithFormat } from "../utils";
import { EventBus } from "../eventbus";

export default {
  name: "TableOfWorkTime",
  setup() {},
  data: () => ({
    headers: [
      { text: "日期", align: "start", sortable: false, value: "date" },
      { text: "上班时间", sortable: false, value: "start" },
      { text: "下班时间", sortable: false, value: "end" },
      { text: "工时", sortable: false, value: "workTime" },
    ],
    attendanceData: [],
    dialog: false,
    sortDesc: true,
    formTitle: "添加工时",
    editedItem: {
      date: new Date().format("yyyy-MM-dd"),
      start: "09:00:00",
      end: "17:30:00",
      workTime: 0,
    },
    defaultItem: {
      date: new Date().format("yyyy-MM-dd"),
      start: "09:00:00",
      end: "17:30:00",
      workTime: 0,
    },
    editedIndex: -1,
    errorMessage: "",
    showDeleteBtn: false,
    search: new Date().format('yyyy-MM'),
  }),
  methods: {
    showFormattedWorkTime(time) {
      return calcWorkTimeWithFormat(time);
    },
    initData() {
      this.attendanceData = getAttendanceData();
    },
    save() {
      if (!this.checkIfDateExist(this.editedItem.date)) {
        return;
      }
      this.editedItem.start =
        this.editedItem.start.split(":").length == 2
          ? this.editedItem.start + ":00"
          : this.editedItem.start;
      this.editedItem.end =
        this.editedItem.end.split(":").length == 2
          ? this.editedItem.end + ":00"
          : this.editedItem.end;
      this.editedItem.workTime = calcWorkTime(
        this.editedItem.date + " " + this.editedItem.start,
        this.editedItem.date + " " + this.editedItem.end
      );
      if (this.editedIndex > -1) {
        Object.assign(this.attendanceData[this.editedIndex], this.editedItem);
      } else {
        this.attendanceData.push(this.editedItem);
      }
      setAttendanceData(this.attendanceData);
      this.close();
    },
    deleteItem() {
      this.attendanceData.splice(this.editedIndex, 1);
      setAttendanceData(this.attendanceData);
      this.close();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.showDeleteBtn = false;
        this.errorMessage = "";
      });
    },
    handleRowClick(item) {
      this.editedIndex = this.attendanceData.indexOf(item);
      this.editedItem = item;
      this.dialog = true;
      this.showDeleteBtn = true;
    },
    checkIfDateExist(date) {
      if (this.editedIndex > -1) {
        return true;
      }
      if (!this.dateCheck(date)) {
        this.errorMessage = "日期已存在";
        return false;
      }
      return true;
    },
    dateCheck(date) {
      if (this.editedIndex > -1) {
        return true;
      }
      for (let i = 0; i < this.attendanceData.length; i++) {
        if (this.attendanceData[i].date == date) {
          return false;
        }
      }
      return true;
    },
    getWorkDays() {
      let currentMonth = new Date().format("yyyy-MM");
      return this.attendanceData.filter(
        (d) => d.date.indexOf(currentMonth) > -1
      ).length;
    },
    getWorkTime() {
      let currentMonth = new Date().format("yyyy-MM");
      let time = this.attendanceData
        .filter((d) => d.date.indexOf(currentMonth) > -1)
        .map((d) => d.workTime)
        .reduce((total, num) => total + num, 0);
      return time;
    },
    getExtraWorkTime() {
      let currentMonth = new Date().format("yyyy-MM");
      let time = this.attendanceData
        .filter((d) => d.date.indexOf(currentMonth) > -1)
        .map((d) => d.workTime)
        .reduce((total, num) => total + num, 0);
      return time - this.getWorkDays() * 8 * 3600 * 1000;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    "editedItem.date": function () {
      this.errorMessage = "";
    },
  },
  mounted() {
    this.initData();
    EventBus.$on("attendanceDataRefreshed", () => {
      this.initData();
    });
  },
  beforeDestroy() {
    EventBus.$off("attendanceDataRefreshed");
  },
};
</script>
<template>
  <v-app>
    <v-app-bar color="deep-purple accent-4" dark app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <!-- <v-btn class="ma-2" color="error"> 点击打卡 </v-btn> -->
      <check-in></check-in>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute app temporary>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item to="/foo">
            <v-list-item-title>Foo</v-list-item-title>
          </v-list-item>

          <v-list-item to="/bar">
            <v-list-item-title to="/bar">Bar</v-list-item-title>
          </v-list-item>

          <v-list-item to="/detail">
            <v-list-item-title>工时详情</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <!-- <calendar-of-work-time/> -->
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import HelloWorld from "./components/HelloWorld";
import CalendarOfWorkTime from "./components/CalendarOfWorkTime.vue";
import CheckIn from "./components/CheckIn.vue";

export default {
  name: "App",

  components: {
    HelloWorld,
    CalendarOfWorkTime,
    CheckIn,
  },

  data: () => ({
    drawer: false,
    group: null,
  }),
};
</script>

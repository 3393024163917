Date.prototype.format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1,                 //月份 
        "d+": this.getDate(),                    //日 
        "h+": this.getHours(),                   //小时 
        "m+": this.getMinutes(),                 //分 
        "s+": this.getSeconds(),                 //秒 
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
        "S": this.getMilliseconds()             //毫秒 
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    }
    for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
    }
    return fmt;
}

function calcTime(start, end) {
    return Date.parse(end) - Date.parse(start);
}

function calcTimeWithFormat(start, end) {
    let time = calcTime(start, end);
    let hours = time / 1000 / 3600;
    let minutes = (time - hours * 3600 * 1000) / 1000 / 60;
    return hours + '时' + minutes + '分';
}


function calcWorkTime(start, end) {
    // 工时计算，需要去掉中午的12:00-13:30
    let startDay = start.split(' ')[0];
    let endDay = end.split(' ')[0];
    if (startDay == endDay) {
        let intervalStartTime = Date.parse(startDay + ' ' + '12:00:00');
        let intervalEndTime = Date.parse(startDay + ' ' + '13:30:00');
        let intervalEveningStartTime = Date.parse(startDay + ' ' + '17:30:00');
        let intervalEveningEndTime = Date.parse(startDay + ' ' + '18:00:00');
        let startTime = Date.parse(start);
        let endTime = Date.parse(end);
        if (startTime > endTime) {
            return 0;
        }
        // 前提有下面两个，start小于end，intervalStart小于intervalEnd
        if (startTime < intervalStartTime) {
            if (endTime < intervalStartTime) {
                // 上班卡在上午，下班卡在上午
                return endTime - startTime;
            }
            else if (intervalStartTime <= endTime && endTime <= intervalEndTime) {
                // 上班卡在上午，下班卡在中午
                return intervalStartTime - startTime;
            } else if (intervalEndTime <= endTime && endTime <= intervalEveningStartTime) {
                // 上班卡在上午，下班卡在下午
                return intervalStartTime - startTime + endTime - intervalEndTime;
            } else if (intervalEveningStartTime <= endTime && endTime <= intervalEveningEndTime) {
                // 上班卡在上午，下班卡在傍晚
                return intervalStartTime - startTime + intervalEveningStartTime - intervalEndTime;
            } else {
                // 上班卡在上午，下班卡在晚上
                return intervalStartTime - startTime + intervalEveningStartTime - intervalEndTime + endTime - intervalEveningEndTime;
            }
        } else if (intervalStartTime <= startTime && startTime <= intervalEndTime) {
            if (intervalStartTime <= endTime && endTime <= intervalEndTime) {
                //上班卡在中午，下班卡也在中午
                return 0;
            } else if (intervalEndTime <= endTime && endTime <= intervalEveningStartTime) {
                //上班卡在中午，下班卡在下午
                return endTime - intervalEndTime;
            } else if (intervalEveningStartTime <= endTime && endTime <= intervalEveningEndTime) {
                // 上班卡在中午，下班卡在傍晚
                return intervalEveningStartTime - intervalEndTime;
            } else {
                // 上班卡在中午，下班卡在晚上
                return intervalEveningStartTime - intervalEndTime + endTime - intervalEveningEndTime;
            }
        } else if (intervalEndTime <= startTime && startTime <= intervalEveningStartTime) {
            if (intervalEndTime <= endTime && endTime <= intervalEveningStartTime) {
                //上班卡在下午，下班卡在下午
                return endTime - startTime;
            } else if (intervalEveningStartTime <= endTime && endTime <= intervalEveningEndTime) {
                // 上班卡在下午，下班卡在傍晚
                return intervalEveningStartTime - startTime;
            } else {
                //上班卡在下午，下班卡在晚上
                return intervalEveningStartTime - startTime + endTime - intervalEveningEndTime;
            }
        } else if (intervalEveningStartTime <= startTime && startTime <= intervalEveningEndTime) {
            if (intervalEveningStartTime <= endTime && endTime <= intervalEveningEndTime) {
                // 上班卡在傍晚，下班卡也在傍晚
                return 0;
            } else {
                //上班卡在傍晚，下班卡在晚上
                return endTime - intervalEveningEndTime;
            }
        } else {
            // 上班卡在晚上，下班卡在晚上
            return endTime - startTime;
        }
    }
}

function calcWorkTimeWithFormat(timeInMills) {
    // let hours = Math.floor(timeInMills / 1000 / 3600);
    // let minutes = Math.floor((timeInMills - hours * 3600 * 1000) / 1000 / 60);
    // if (hours > 0) {
    //     return hours + '时' + minutes + '分';
    // } else if (minutes > 0) {
    //     return minutes + '分';
    // }
    // return 0;
    return Math.floor(timeInMills / 1000 / 3600 * 100) / 100 + '小时';
}
export {
    calcTime,
    calcWorkTime,
    calcTimeWithFormat,
    calcWorkTimeWithFormat
}
<template>
  <div>
    <v-calendar
      ref="calendar"
      v-model="focus"
      color="primary"
      :events="events"
      :event-color="getEventColor"
      :type="type"
      @click:event="showEvent"
      @click:more="viewDay"
      @click:date="viewDay"
      @change="updateRange"
    ></v-calendar>
  </div>
</template>

<script>
export default {
  name: "CalendarOfWorkTime",
  setup() {},
  data: () => ({
    focus: "",
    type: "month",
    events: [
      {
        name: "上班打卡",
        start: new Date(),
      },
    ],
  }),
};
</script>
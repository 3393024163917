import HelloWorld from './components/HelloWorld';
import CalendarOfWorkTime from './components/CalendarOfWorkTime'
import TableOfWorkTime from './components/TableOfWorkTime'

var routers = [
    { path: '/foo', component: HelloWorld },
    { path: '/bar', component: CalendarOfWorkTime },
    { path: '/detail', component: TableOfWorkTime }
]

export {
    routers
}
function getAttendanceData() {
    let data = localStorage.getItem('attendance');
    if (data) {
        return JSON.parse(data);
    }
    return [];
}

function addOneAttendanceData(d) {
    let data = getAttendanceData();
    data.push(d);
    setAttendanceData(data);
}

function removeOneAttendanceData(index) {
    let data = getAttendanceData();
    if (data.length > index) {
        data.splice(index, 1);
    }
    setAttendanceData(data);
}

function setAttendanceData(data) {
    localStorage.setItem('attendance', JSON.stringify(data));
}

export {
    getAttendanceData,
    addOneAttendanceData,
    removeOneAttendanceData,
    setAttendanceData
}
<template>
  <div>
    <!-- <v-btn class="ma-2" color="error"> 点击打卡 </v-btn> -->

    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="red" dark v-bind="attrs" v-on="on" @click="doCheckIn()">
          点击打卡
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 success lighten-2">
          打卡成功
        </v-card-title>

        <v-card-text class="text-h6">
          <strong>当前时间：{{ showCurrentTime() }}</strong>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> 我知道了 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getAttendanceData, setAttendanceData } from "../data";
import { calcWorkTime } from "../utils";
import { EventBus } from "../eventbus";

export default {
  name: "CheckIn",
  setup() {},
  data: () => ({
    dialog: false,
  }),
  methods: {
    doCheckIn() {
      let currentTimeString = this.showCurrentTime();
      let currentDate = currentTimeString.split(" ")[0];
      let currentTime = currentTimeString.split(" ")[1];
      let attendanceData = getAttendanceData();
      if (attendanceData.length > 0) {
        let index = -1;
        for (let i = 0; i < attendanceData.length; i++) {
          if (attendanceData[i].date == currentDate) {
            index = i;
            break;
          }
        }
        if (index > -1) {
          let lastAttendanceData = attendanceData[index];
          lastAttendanceData.end = currentTime;
          lastAttendanceData.workTime = calcWorkTime(
            lastAttendanceData.date + " " + lastAttendanceData.start,
            currentTimeString
          );
          setAttendanceData(attendanceData);
          EventBus.$emit("attendanceDataRefreshed");
          return;
        }
      }
      let data = {
        date: currentDate,
        start: currentTime,
        end: "-",
        workTime: 0,
      };
      attendanceData.push(data);
      setAttendanceData(attendanceData);
      EventBus.$emit("attendanceDataRefreshed");
    },
    showCurrentTime() {
      return new Date().format("yyyy-MM-dd hh:mm:ss");
    },
  },
};
</script>